@use '../../../styles/components' as *;
@use '../../../styles/colors' as *;
@use '../../../styles/spacing' as *;
@use '../../../styles/typography' as *;

.milestone-label-outer-wrapper {
    display: flex;
    align-items: center;
    justify-self: center;
    border-radius: 42px;
    background: conic-gradient(from 66.21deg at 66.21% 40%, 
        #225CAF 0deg,
        #AE342D 65.89deg,
        #B28013 130.46deg, 
        #BC5746 186.97deg,
        #CC1896 254.81deg,
        #869E1A 325.35deg,
        #225CAF 360deg
    );

    .milestone-label-internal-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        gap: $space-fixed-nano;
        padding: 6px 12px;
        border-radius: 42px;
        background-color: $eth-black-light;
        margin: 2px;

        &::before {
            content: '';
            position: absolute;
            top: 0.5px;
            bottom: -4.5px;
            left: 0px;
            right: -18px;
            background: conic-gradient(from 51.21deg at 29.32% 55.21%, #225CAF 0deg, #AE342D 65.89deg, #B28013 130.46deg, #BC5746 186.97deg, #CC1896 254.81deg, #869E1A 325.35deg, #225CAF 360deg);
            filter: blur(28px);
            opacity: 0.5;
            z-index: 0;
        }

        .milestone-label {
            margin-top: 2px;
            width: max-content;
            font-family: DIN Next W1G Bold;
            font-size: $font-size-l3;
            line-height: $font-line-height-l3;
            font-weight: $font-weight-bold;
            color: $eth-white;
            z-index: 1;
        }

        .milestone-icon {
            z-index: 1;
        }
    }
}