@use '../../styles/colors' as *;
@use '../../styles/components' as *;
@use '../../styles/typography' as *;


.button {
    border-radius: $border-radius-default;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    padding: 14px 32px 13px 32px;
    transition: background-color 0.3s ease;
    border: 1px solid;

    &.button-only-icon {
        padding: 14px;

        .button-icon {
            line-height: 0;
        }
    }

    &.small {
        padding: 8px 16px 8px 16px;
    }
}

.button-primary {
    border-color: $eth-black-light;
    background-color: $eth-black-light;
    color: $eth-white;

    &:hover {
        background-color: $eth-black;
        border-color: $eth-black-light;
    }

    &:disabled {
        background-color: $eth-grey;
        border-color: $eth-grey;
        color: $eth-black-light-31;
    }

    &.inverted {
        border-color: $eth-white;
        background-color: $eth-white;
        color: $eth-black-light;

        span svg {
            fill: $eth-black-light;
        }

        &:hover {
            background-color: $eth-white-with-opacity;
            border-color: $eth-white;
            color: $eth-black;
        }

        &:disabled {
            background-color: $eth-white;
            opacity: 0.4;
            border-color: $eth-white;
            color: $eth-black-light-60;
        }
    }
}

.button-secondary {
    border-color: $eth-black-light-20;
    background-color: $eth-white;
    color: $eth-black-light;

    &:hover {
        border-color: $eth-black;
    }

    &:disabled {
        border-color: $eth-black-light-20;
        color: $eth-black-light-31;
    }

    &.inverted {
        border-color: $eth-white;
        background-color: unset;
        color: $eth-white;

        span svg {
            fill: $eth-white;
        }

        &:hover {
            background-color: $eth-white-with-opacity;
            border-color: $eth-white-with-opacity;
            color: $eth-black-light;

            span svg {
                fill: $eth-black-light;
            }
        }

        &:disabled {
            background-color: unset;
            opacity: 0.4;
            border-color: $eth-white;
            color: $eth-white;
        }
    }
}

.button-text {
    align-self: stretch;
    gap: 10px;
    font-family: DIN Next W1G Medium;
    font-size: $font-size-p3;
    font-style: normal;
    font-weight: $font-weight-semi-bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
