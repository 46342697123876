@use '../../../../styles/colors.scss' as *;
@use '../../../../styles/typography' as *;

.approach-container {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform, opacity;

    @media (max-width: 450px) {
        height: fit-content;
        transform: translate3d(-50%, -48%, 0);
        @media (min-height: 700px) {
            height: fit-content;
            transform: translate3d(-50%, -50%, 0);
        }
    }
}

.approach-image-container {
    display: none;
    border-radius: 10px;
    width: 32%;
    max-width: 570px;
    max-height: 640px;
    height: 77%;
    transform: translate3d(0, -10%, 0);
    position: relative;
    will-change: transform, opacity;
}

.approach-image {
    position: absolute;
    width: 265%;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate3d(-70%, -57%, 0);
    z-index: -1;
    will-change: transform;

    @media (max-width: 1400px) {
        transform: translate3d(-60%, -57%, 0);
    }
}

.approach-leadership {
    margin-bottom: 48px;
    font-size: 48px;
    @media (max-width: 1000px) and (max-height: 450px) {
        font-size: 32px !important;
    }
    @media (max-width: 1400px) {
        margin-bottom: 15px;
        font-size: 40px;
    }
}

.approach-left-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    will-change: transform, opacity;
}

.approach-text-box {
    width: 100%;
    max-width: 500px;
    height: 90%;
    @media (max-width: 1000px) and (max-height: 450px) {
        width: 85%;
    }
}

.approach-bold-text {
    font-weight: 700;
    font-size: 20px;
    @media (max-width: 1400px) {
        font-size: 18px;
    }
}

.approach-sub-text {
    font-size: 16px;
    @media (max-width: 1400px) {
        font-size: 12px;
    }
}

.approach-card {
    display: flex;
    width: 100%;
    max-width: 415px;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 10px;
    margin-bottom: 50px;
    @media (max-width: 1360px) {
        margin-bottom: 10px;
    }
}

.approach-highlight {
    width: fit-content;
    white-space: nowrap;
    background-size: 200%;
    padding: 0 4px;
    background-image: linear-gradient(to right, transparent 50%, $eth-highlight-orange 50%);
}
.approach-highlight-1 {
    width: fit-content;
    white-space: nowrap;
    background-size: 200%;
    padding: 0 4px;
    background-image: linear-gradient(to right, transparent 50%, $eth-highlight-orange 50%);
}
.leadership-br {
    @media (max-width: 500px) {
        display: none;
    }
}

.approach-image-container {
    border-radius: 10px;
    position: relative;
    transform: translate3d(0, -10%, 0);
    will-change: transform;
}

.approach-noise {
    width: 100%;
    position: absolute;
    top: 45%;
    left: 52%;
    scale: 0.8;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
}

// Mobile version
.approach-image-container-mob {
    display: none;
    border-radius: 10px;
    width: 110%;
    max-width: 570px;
    max-height: 640px;
    min-height: 322px;
    height: 77%;
    position: relative;
    top: -77px;
    @media (max-width: 400px) {
        width: 100%;
    }
    @media (max-width: 380px) {
        width: 95%;
    }
    @media (max-width: 860px) {
        top: -95px;
    }
    @media (max-width: 450px) and (max-height: 720px) {
        width: 70%;
        top: -60px;
    }
}

.approach-image-mob {
    width: 100%;
}

.approach-leadership-mob {
    font-size: 32px;
    text-align: center;
}


.approach-text-box-mob {
    width: 100%;
    max-width: 400px;
    gap: 14px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    will-change: transform, opacity;
    @media (max-height: 860px) {
        gap: 0;
    }
}

.approach-bold-text-mob {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
}

.mobile-card-container {
    position: relative;
    height: 174px;
    width: 100%;
}

.mobile-card-slab {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.approach-card-mob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 344px;
    width: 88%;
    margin: 0 auto 50px;
    gap: 10px;
    @media (max-width: 450px) {
        margin: 0 auto 25px;
    }
    @media (max-height: 860px) {
        margin: 0 auto 0;
        gap: 0px;
    }
}

.approach-highlight-mob {
    width: fit-content;
    white-space: nowrap;
    background-size: 200%;
    padding: 0 4px;
    text-align: center;
    margin: auto;
    background-image: linear-gradient(to right, transparent 50%, $eth-highlight-orange 50%);
}

.approach-highlight-mob-1 {
    width: fit-content;
    white-space: nowrap;
    background-size: 200%;
    padding: 0 4px;
    text-align: center;
    margin: auto;
    background-image: linear-gradient(to right, transparent 50%, $eth-highlight-orange 50%);
}

.leadership-br-mob {
    @media (max-width: 500px) {
        display: none;
    }
}

.approach-noise-mob {
    width: 100%;
    position: absolute;
    top: 45%;
    left: 52%;
    scale: 0.8;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform, opacity;
}

.approach-icon {
    margin-bottom: 10px;
}

.approach-icon-wrapper {
    margin-bottom: 10px;
}

.approach-container-mob {
    margin-top: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    will-change: transform, opacity;
    @media (max-height: 860px) {
        margin-top: 0;
    }
}

.points {
    position: absolute;
    left: 50%;
    z-index: 99;
    transform: translate3d(-50%, -50%, 0);
    width: 54px;
    display: flex;
    justify-content: space-between;
    will-change: transform;

    @media (max-height: 860px) {
        margin-top: 12px;   
    }
}

.desktop-number-icon {
    @media (max-width: 1000px) and (max-height: 450px) {
        width: 20px;
        height: 20px;
    }
}

.approach-1,
.approach-2,
.approach-3,
.approach-4,
.approach-card-animation-0,
.approach-card-animation-1,
.approach-card-animation-2 {
    will-change: transform, opacity;
}
