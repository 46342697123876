@use '../../../../styles/colors.scss' as *;
@use '../../../../styles/typography' as *;

.faq-container {
    position: relative;
    height: 200vh !important;
    margin-top: -300px;
    overflow-x: hidden;
    padding: 0 160px !important;
    @media (max-width: 1400px) {
        margin-top: 500px;
        padding: 0 120px !important;
    }
    @media (max-width: 1100px) {
        margin-top: 500px;
        padding: 0 30px !important;
    }

    @media (max-width: 450px) {
        height: max-content !important;
        margin-top: 0;
        padding: 0 16x;
    }
}

.no-data-found {
    margin: auto;
    width: 100%;
    text-align: center;
}

.faq-pattern {
    display: none;
    position: absolute;
    width: 100%;
    height: 55%;
    top: 0;
    right: 1%;
    transform: translate3d(0, -11%, 0);
    overflow: hidden;
    will-change: transform;
}

.faq-twist {
    top: 45%;
    left: 39%;
    position: absolute;
    width: 150%;
    opacity: 0;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
}

.faq-background {
    display: none;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    object-fit: cover;
    z-index: 0;

    @media (max-width: 450px) {
        display: none;
        opacity: 0;
    }
}

.guidance-container {
    display: none;
    height: 100vh;
    width: 100%;
    color: white;
    margin-bottom: 0;
    padding: 40px 0;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 450px) {
        display: none;
    }
}

.guidance-title {
    grid-column: span 5 / span 5;
    line-height: 0.7;
    height: 100px;
    z-index: 2;
    @media (max-width: 1400px) {
        font-size: 30px;
    }
    @media (max-width: 768px) {
        font-size: $font-size-h2;
    }
    @media (max-width: 500px) {
        line-height: 1.2;
    }
    font-size: 43px;
    grid-column: span 5 / span 5;
    line-height: 1;
    max-width: 500px;
    height: 100px;
    z-index: 2;
}

.guidance-description {
    grid-column: span 7 / span 7;
}

.guidance-details {
    opacity: 0.8;
    line-height: 1.8;
    font-family: 'DIN Next W1G Light';
    font-size: 20px;
    @media (max-width: 1400px) {
        font-size: 14px;
    }
}

.guidance-span {
    display: flex;
    padding: 10px 10px !important;
    white-space: nowrap;
    background-repeat: no-repeat;
    background-image: url(../../../../assets/img/highlight.png);
    background-size: 102% 100%;
}

.guidance-br {
    @media (max-width: 500px) {
        display: none;
    }
}

.guidance-top {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 10px;
    margin-bottom: 50px;
}

.card-heart {
    background: linear-gradient(rgba(34, 34, 34, 0.8), rgba(34, 34, 34, 0.8)),
        url('../../../../assets/backgrounds/heart-card.png') !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.bottom-card-icon-1 {
    margin-top: 64px;
}

.bottom-card-icon-2 {
    margin-top: 65px;
}
.bottom-card-icon-3 {
    margin-top: 64px;
}

.card-icon {
    margin-bottom: 24px;
    margin-top: 64px;
    @media (max-height: 830px) {
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.card-title {
    font-size: 24px;
    @media (max-height: 830px) {
        font-size: 16px;
    }
}
.card-description {
    font-family: 'DIN Next W1G Light';
    width: 100%;
    font-size: 16px;
    opacity: 0.8;
    @media (max-height: 830px) {
        font-size: 10px;
    }
}

.two-cards {
    padding: 2rem;
    border-radius: 10px;
    background: linear-gradient(rgba(34, 34, 34, 0.9), rgba(34, 34, 34, 0.9)), url('../../../../assets/backgrounds/card.png');
    background-size: cover;
    height: 100%;
    grid-column: span 6 / span 6;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

@media (min-width: 450px) {
    .black-gradient {
        -moz-box-shadow: inset 0 -290px 224px -202px #121212;
        -webkit-box-shadow: inset 0 -290px 224px -202px #121212;
        box-shadow: inset 0 -290px 224px -202px #121212;
    }
    .two-cards {
        box-shadow: 0px 4px 12px 0px #00000033;
    }
    .one-big-card {
        box-shadow: 0px 4px 12px 0px #00000033;
    }
    .two-small-cards {
        box-shadow: 0px 4px 12px 0px #00000033;
    }
    .bottom-card-2 {
        box-shadow: 0px 4px 12px 0px #00000033;
    }
    .bottom-card-3 {
        box-shadow: 0px 4px 12px 0px #00000033;
    }
    .guidance-span-mob {
        box-shadow: 0px 2px 1px 0px rgba(255, 255, 255, 0.3490196078) inset;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3490196078);
    }
    .two-cards-mob {
        box-shadow: 0px 4px 12px 0px #00000033;
    }
    .one-big-card-mob {
        box-shadow: 0px 4px 12px 0px #00000033;
    }
    .two-small-cards-mob {
        box-shadow: 0px 4px 12px 0px #00000033;
    }
    .mob-card {
        box-shadow: 0px 4px 12px 0px #00000033;
    }
}

.col-span-6 {
    grid-column: span 6 / span 6;
    border-radius: 10px;
    background-color: rgba(34, 34, 34, 0.9);
    width: 100%;
    height: 100%;
    z-index: 2;
}

.guidance-top-cards {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 20px;
    margin-bottom: 20px;
}

.guidance-bottom-cards {
    display: grid;
    grid-template-columns: repeat(13, minmax(0, 1fr));
    gap: 20px;
}

.card-background {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #222222;
    top: 0;
    left: 0;
    z-index: -99;
}

.one-big-card {
    padding: 2rem;
    height: 100%;
    border-radius: 10px;
    background: linear-gradient(rgba(34, 34, 34, 0.9), rgba(34, 34, 34, 0.9)), url('../../../../assets/backgrounds/card.png');
    background-size: cover;
    height: 334px;
    grid-column: span 5 / span 5;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    cursor: pointer;
}

.two-small-cards {
    font-family: 'DIN Next W1G Light';
    padding: 40px 60px;
    height: 100%;
    border-radius: 10px;
    background: linear-gradient(rgba(34, 34, 34, 0.6), rgba(34, 34, 34, 0.6)),
        url('../../../../assets/backgrounds/small-card.png');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    cursor: pointer;
    overflow: hidden;
}

.bottom-big-cards {
    font-family: 'DIN Next W1G Light';
    border-radius: 10px;
    background-color: rgba(34, 34, 34, 1);
    grid-column: span 5 / span 5;
    width: 100%;
    height: 100%;
    background-position: -45px;
    background-repeat: no-repeat;
    z-index: 2;
}

.bottom-small-cards {
    font-family: 'DIN Next W1G Light';
    grid-column: span 4 / span 4;
    z-index: 2;
    border-radius: 10px;
    background-color: rgba(34, 34, 34, 1);
    width: 100%;
    height: 100%;
    background-position: -50px;
    background-repeat: no-repeat;
    z-index: 2;
}

.card-1 {
    padding: 40px 60px;
}

.bottom-card-2 {
    padding: 39px 27px;
    border: 1px solid;
    border-image-source: radial-gradient(circle at 2.08% 2.69%, rgba(241, 241, 241, 0.5) 0%, rgba(121, 121, 121, 0) 100%);
}

.bottom-card-3 {
    padding: 40px 22px;
    border: 1px solid;
    border-image-source: radial-gradient(circle at 2.08% 2.69%, rgba(241, 241, 241, 0.5) 0%, rgba(121, 121, 121, 0) 100%);
}

// qna sections
.qna-container {
    display: none;
    grid-template-columns: repeat(14, minmax(0, 1fr));
    color: white;
    overflow: hidden;
    bottom: 0 !important;
    width: 100%;
    @media (max-width: 450px) {
        display: none;
    }
}

.qna-wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    @media (max-width: 450px) {
        display: none;
    }
}

.qna-heading {
    width: 100%;
    font-size: 48px;
    line-height: 1;
    height: 100px;
    grid-column: span 5 / span 5;
    z-index: 2;
    padding-top: 10px;
    @media (max-width: 1400px) {
        grid-column: span 4 / span 4;
        font-size: 30px;
    }
    @media (max-width: 768px) {
        font-size: $font-size-h2;
    }
    @media (max-width: 500px) {
        line-height: 1.2;
    }
}

.qna-span {
    padding: 4px 4px 10px 4px;
    white-space: nowrap;
    background-repeat: no-repeat;
    display: inline-flex;
    height: fit-content;
    background-image: url(../../../../assets/img/highlight-long.png);
    background-size: 104% 100%;
}

.qna-shadow {
    position: relative;
    width: 100%;
    grid-column: span 9 / span 9;
    height: 420px;
    @media (max-width: 1400px) {
        height: 300px;
    }
}

.pb-50 {
    padding-bottom: 150px;
}

.qna-questions {
    width: 85%;
    grid-column: span 9 / span 9;
    overflow: auto;
    height: 420px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    @media (max-width: 1400px) {
        height: 400px;
        grid-column: span 10 / span 10;
    }
}

.qna-question::-webkit-scrollbar {
    display: none;
}

.black-gradient {
    position: absolute;
    width: 100%;
    z-index: 9;
    height: 100%;
    pointer-events: none;
}

.qna-question {
    font-family: 'DIN Next W1G Light';
    font-size: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    @media (max-width: 450px) {
        font-size: 18px;
    }
}

.qna-question-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 16px;
    padding-bottom: 110px;
    width: 100%;
}

.qna-seperator {
    background-color: rgba(255, 255, 255, 0.1);
    height: 1px;
    width: 100%;
}

.qna-seperator-black {
    background-color: rgba(0, 0, 0, 0.4);
    height: 1px;
    width: 100%;
    margin-top: 23px;
}

.qna-answer {
    font-family: 'DIN Next W1G Light';
    font-size: 16px;
    font-weight: 400;
    opacity: 0.8;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
}

.qna-single-question {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    width: 100%;
}

.open-question {
    max-height: 6000px;
    opacity: 1;
}

.close-question {
    max-height: 0px;
    opacity: 0;
}

// Mobile section
.no-data-found-mob {
    margin: auto;
    width: 100%;
    text-align: center;
}

.faq-background-mob {
    display: none;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    object-fit: cover;
    z-index: 0;
    transform: translate3d(0, 0, 0);
    will-change: transform, opacity;

    @media (max-width: 450px) {
        display: block;
    }
}

.guidance-container-mob {
    display: none;
    height: 100vh;
    color: white;
    margin-bottom: 300px;
    width: 100%;
    padding: 30px 0;
    @media (max-width: 1400px) {
        height: fit-content;
        margin-bottom: 0;
    }
    @media (max-width: 450px) {
        display: block;
        opacity: 0;
    }
}

.guidance-title-mob {
    grid-column: span 5 / span 5;
    font-size: 33px;
    line-height: 0.7;
    height: 100px;
    z-index: 2;
    will-change: opacity;
    @media (max-width: 768px) {
        font-size: $font-size-h2;
    }
    @media (max-width: 500px) {
        line-height: 1.2;
        height: auto;
    }
    @media (max-width: 450px) {
        opacity: 0;
    }
}

.guidance-description-mob {
    grid-column: span 7 / span 7;
    margin-top: 20px;
    will-change: transform, opacity;
    @media (max-width: 450px) {
        opacity: 0;
    }
}

.guidance-details-mob {
    opacity: 0.8;
    line-height: 24px;
    font-family: 'DIN Next W1G Light';
    font-size: 16px;
    will-change: transform, opacity;

    @media (max-width: 450px) and (max-height: 720px) {
        font-size: 14px;
    }
}

.guidance-span-mob {
    padding: 4px 3px;
    white-space: nowrap;
    background-size: 104%;
    background-image: url(../../../../assets/img/highlight-long.png);
    background-repeat: no-repeat;

    @media (max-width: 450px) {
        background-position: -250px 8px;
    }
}

.guidance-br-mob {
    @media (max-width: 500px) {
        display: none;
    }
}

.guidance-top-mob {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    margin-top: 10px;
}

.card-2-mob {
    background: linear-gradient(rgba(34, 34, 34, 0.8), rgba(34, 34, 34, 0.8)),
        url('../../../../assets/backgrounds/heart-card.png') !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    padding: 40px 53px;
}

.card-icon-mob {
    margin-bottom: 24px;
}

.card-title-mob {
    font-size: 24px;

    @media (max-width: 1400px) {
        font-size: 18px;
    }
}
.card-description-mob {
    font-family: 'DIN Next W1G Light';
    width: 100%;
    font-size: 16px;
    opacity: 0.8;
    @media (max-width: 1400px) {
        font-size: 10px;
    }
}

.two-cards-mob {
    padding: 2rem;
    border-radius: 10px;
    background: linear-gradient(rgba(34, 34, 34, 0.9), rgba(34, 34, 34, 0.9)), url('../../../../assets/backgrounds/card.png');
    background-size: cover;
    height: 100%;
    grid-column: span 6 / span 6;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
}

.guidance-top-cards-mob {
    display: flex;
    justify-content: center;
    align-items: start;
    margin-bottom: 20px;
    margin-top: 40px;
}

.guidance-cards-mob {
    position: relative;
    height: 492px;
}

.one-big-card-mob {
    padding: 2rem;
    border-radius: 10px;
    background: linear-gradient(rgba(34, 34, 34, 0.9), rgba(34, 34, 34, 0.9)), url('../../../../assets/backgrounds/card.png');
    background-size: cover;
    height: 334px;
    grid-column: span 5 / span 5;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
    cursor: pointer;
}

.two-small-cards-mob {
    padding: 2rem;
    border-radius: 10px;
    background: linear-gradient(rgba(34, 34, 34, 0.9), rgba(34, 34, 34, 0.9)), url('../../../../assets/backgrounds/card.png');
    background-size: cover;
    height: 334px;
    grid-column: span 4 / span 4;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
    cursor: pointer;
}

// qna sections
.qna-container-mob {
    display: none;
    color: white;
    height: 100vh;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 30px 0;
    @media (max-width: 450px) {
        display: flex;
        opacity: 0;
    }
}

.qna-heading-mob {
    width: 100%;
    font-size: $font-size-h1;
    line-height: 1;
    z-index: 2;
    margin-bottom: 24px;
    padding: 10px 0;
}

.qna-span-mob {
    padding: 10px 10px;
    white-space: nowrap;
    background-position: -3px 12px;
    background-repeat: no-repeat;
    background-image: url(../../../../assets/img/highlight.png);
}

.qna-shadow-mob {
}

.pb-50-mob {
    padding-bottom: 150px;
}

.qna-questions-mob {
    width: 100%;
    grid-column: span 9 / span 9;
    overflow: auto;
    height: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
    z-index: 2;
}

.qna-question::-webkit-scrollbar {
    display: none;
}

.black-gradient-mob {
    position: absolute;
    bottom: -10px;
    width: 100%;
    z-index: 9;
}

.qna-question-mob {
    font-family: 'DIN Next W1G Light';
    font-size: 1.4rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    margin-bottom: 10px;
    @media (max-width: 1400px) {
        font-size: 1rem;
    }
}

.qna-question-box-mob {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 30px;
}

.qna-answer-mob {
    font-family: 'DIN Next W1G Light';
    font-size: 12px;
    font-weight: 400;
    opacity: 0.8;
    overflow: hidden;
    transition-property: max-height;
    transition-duration: 1s;
}

.qna-single-question-mob {
    font-size: 18px;
    line-height: 27px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
}

.open-question-mob {
    max-height: 100px;
}

.close-question-mob {
    max-height: 0px;
}

.mob-card {
    width: 100%;
    padding: 2rem;
    border-radius: 10px;
    background: linear-gradient(rgba(34, 34, 34, 0.9), rgba(34, 34, 34, 0.9)), url('../../../../assets/backgrounds/card.png');
    background-size: cover;
    height: 334px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
    cursor: pointer;
    will-change: transform, opacity;
    @media (max-height: 830px) {
        height: 200px;
        padding: 20px;
    }
    @media (max-width: 450px) and (max-height: 720px) {
        height: 180px;
    }
}

.faq-twist-mob {
    display: none;
    left: 0;
    position: absolute;
    width: 100%;
    opacity: 0;
    will-change: opacity;

    @media (max-width: 450px) {
        display: block;
    }
}

.qna-seperator {
    background-color: rgba(255, 255, 255, 0.1);
    height: 1px;
    width: 100%;
}

.qna-seperator-black {
    background-color: rgba(0, 0, 0, 0.4);
    height: 1px;
    width: 100%;
}

.faq-points {
    opacity: 0;
    display: none;
    position: absolute;
    top: 77%;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 100px;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
    will-change: transform;

    @media (max-height: 830px) {
        top: 57%;
    }
    @media (max-height: 750px) {
        top: 45%;
    }

    @media (max-width: 450px) {
        display: flex;
    }
    @media (max-width: 450px) and (max-height: 720px) {
        top: 40%;
    }
}

.card-badge-wrapper {
    margin-bottom: 24px;
    margin-top: 64px;
    @media (max-height: 830px) {
        margin-bottom: 10px;
        margin-top: 0px;
    }
}
