@use '../../../../styles/colors.scss' as *;
@use '../../../../styles/typography' as *;

.companion-container {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    will-change: transform, opacity;
}

.companion-bird {
    margin-top: -20px;
    margin-right: -10px;
}

.companion-title {
    display: none;
    font-size: 48px;
    text-align: center;
    max-width: 60%;
    @media (max-width: 1000px) and (max-height: 450px) {
        font-size: 30px;
        max-width: 100%;
    }
}


.tablet-container {
    margin: 0 auto -100px;
    width: 100%;
    display: flex;
    justify-content: center;
}


.tablet-video {
    width: 100%;
    z-index: 1;
    border: 8px solid #222222;
    border-radius: 10px;
    background-color: #222222;
    @media (max-width: 450px) {
        border: 6px solid #222222;
    }
}

.video-container {
    width: 60%;
    height: fit-content;
    position: relative;
    cursor: pointer;
}

.play-button {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate3d(-50%, 78%, 0);
    z-index: 999;
    cursor: pointer;
    pointer-events: none;
    height: fit-content;
    will-change: opacity;
}

.tab-buttons {
    position: absolute;
    left: 4px;
    top: 50%;
    height: 50%;
    transform: translateY(-50%);
    z-index: 99;
}
.pause-button {
    transition: 1s opacity ease-in-out;
    pointer-events: none;
}

.companion-span {
    padding: 0 5px;
    white-space: nowrap;
    background-size: 200%;
    background-image: linear-gradient(to right, transparent 50%, $eth-highlight-orange 50%);
}
.leadership-br {
    @media (max-width: 500px) {
        display: none;
    }
}

.tablet-bird {
    position: relative;
    display: inline;
}

.companion-bird-noise {
    position: absolute;
    top: -111px;
    left: 0px;
    z-index: -1;
}

.companion-subtitle-first {
    position: relative;
}
// Mobile version

.companion-subtitle-first-mob {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 38.4px;
    will-change: transform, opacity;
}

.companion-title-mob {
    display: none;
    font-size: 32px;
    text-align: center;
    max-width: 800px;
    position: relative;
    width: 95%;
}

.companion-span-mob {
    padding: 0 5px;
    white-space: nowrap;
    background-size: 200%;
    background-image: linear-gradient(to right, transparent 50%, $eth-highlight-orange 50%);
    will-change: transform, opacity;
}

.leadership-br-mob {
    @media (max-width: 500px) {
        display: none;
    }
}

.tablet-bird-mob {
    position: relative;
    display: inline;
}

.companion-bird-noise-mob {
    display: none;
    position: absolute;
    top: -70%;
    left: -50px;
    z-index: -1;
}

.tab-container {
    position: relative;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    will-change: transform, opacity;
}

.watch-video {
    border-radius: 2px;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    max-width: 252px;
    height: 45px;
    width: 100%;
    background-color: #222222;
    color: white;
    bottom: -22px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    transition: 1s opacity ease-in-out;
}

.companion-9 {
    margin-top: 20px;
    width: 70%;
    font-size: 18px;
    font-family: 'DIN Next W1G Light';
}

.companion-subtitle-second-mob {
    margin-top: 20px;
    width: 90%;
    font-size: 18px;
    font-family: 'DIN Next W1G Light';
    line-height: 28px;
    margin: auto;
    will-change: transform, opacity;
}

.video-container-mob {
    width: 90%;
    position: relative;
}

.tab-buttons-mob {
    position: absolute;
    height: 80px;
    left: 1px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
}
