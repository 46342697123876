@use '../../styles/components' as *;
@use '../../styles/colors' as *;
@use '../../styles/spacing' as *;

.progress-bar-wrapper {
    position: absolute;
    top: 120px;
    left: -92px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    @media (max-width: 450px) {
        position: static;
        padding-top: 56px;
        height: calc(100vh - 102px);
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 0;
        }
    }
    .css-j7qwjs {
        .css-1rmpr0s {
            padding: 1.9px 0;

            @media (max-height: 975px) {
                padding: 1.3px 0;
            }
           
            @media (max-height: 880px) {
                padding: 1px 0;
            }

            @media (max-height: 820px) {
                padding: 0.8px 0;
            }

            @media (max-height: 790px) {
                padding: 0.4px 0;
            }

            @media (max-height: 690px) {
                padding: 0.2px 0;
            }

            .css-11enh6d {
                height: 2.5px;
                background: #00000099;
                border-radius: $border-radius-12;
                border: 2px solid #000000;
                box-shadow: 0px 1px 0px 0px #ffffff;
                opacity: 0.2;
            }

            &.active {
                .css-11enh6d {
                    background: #00000099;
                    border: 2px solid $eth-black;
                    box-shadow: 0px 1px 0px 0px #ffffff99;
                    opacity: 0.6;
                }
            }

            &.progress-bar-milestone-point-wrapper {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;

                .progress-bar-milestone-point {
                    position: relative;
                    width: 39px;
                    height: 8px;
                    border-radius: $border-radius-12;
                    background: #00000099;
                    border: 1px solid $eth-black;
                    box-shadow: 0px 2px 1px 0px #000000b2 inset;
                    transition: scale 0.3s ease;
                    opacity: 0.2;
                    transform: scale(1) !important;

                    @media (max-width: 450px) {
                        width: 24px;
                    }

                    .milestone-icon {
                        width: 24px;
                        height: 24px;
                    }
                }

                .progress-bar-milestone-tooltip {
                    position: absolute;
                    bottom: 6px;
                    width: 40px;
                    @media (max-width: 450px) {
                        display: none;
                    }
                }

                &.active {
                    .progress-bar-milestone-point {
                        background: $eth-black-light;
                        opacity: 1;
                        box-shadow: 0px 1px 1px 0px #14141433, 0px 1px 1px 0px #FFFFFF59 inset;
                        
                        &::after {
                            content: '';
                            position: absolute;
                            top: -1px;
                            left: -1px;
                            right: -1px;
                            height: 50%;
                            box-shadow: 0px 1px 1px 0px #14141433, 0px 2px 1px 0px #FFFFFF59 inset;
                            z-index: 1;
                            border-top-left-radius: $border-radius-12;
                            border-top-right-radius: $border-radius-12;
                        }
                    }
                }

                &:hover {
                    .progress-bar-milestone-point {
                        scale: 1.1;
                    }

                    .progress-bar-milestone-tooltip {
                        .tooltip-wrapper {
                            transform: translateX(5.5px) translateY(-50%);
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .progress-bar-milestone-lines-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // height: 168px;

        .progress-bar-milestone-line {
            width: 21px;
            height: 0px;
            background: #00000099;
            border: 1px solid $eth-black;
            border-radius: $border-radius-12;
            box-shadow: 0px 1px 0px 0px #ffffff;
            opacity: 0.2;
        }

        &.active {
            .progress-bar-milestone-line {
                background: #00000099;
                border: 2px solid $eth-black;
                box-shadow: 0px 1px 0px 0px #ffffff99;
                opacity: 0.6;
            }
        }
    }
}

.progress-bar-mob-mask {
    position: absolute;
    top: 58px;  
    left: 16px;
    right: 0;
    height: calc(100vh - 360px);
    width: 20px;
    z-index: -1;
    pointer-events: none;
}
