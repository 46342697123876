@use '../../styles/colors' as *;

.features-section {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.features {
    position: absolute;
    background-color: #eeedea;
    top: 0;
    left: 0;
    width: 120%;
    height: 100vh;
    z-index: -1;
}

.innovation {
    background: url('../../assets/backgrounds/innovation.png');
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    overflow: hidden;
}

.innovation-bg {
    display: none;
    @media (max-width: 450px) {
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        z-index: -1;
        transform: translate3d(-50%, 0%, 0);
        height: 100vh;
        will-change: opacity;
    }
}
