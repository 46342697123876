.session-wrapper {
    padding-bottom: 5px;
    height: calc(100vh - 70px);

    @media (max-width: 450px) {
        padding-bottom: 0;
    }
}

.account-details {
    position: absolute;
    left: 11px;
    bottom: 10px;
    border-radius: 100%;
    background-color: #215caf;
    border: 2px solid #1452aa;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    z-index: 99;
    cursor: pointer;
}

.account-letter {
    font-size: 18px;
    font-family: 'DIN Next W1G Bold';
    color: #ffffff;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    cursor: pointer;
}

.account-dropdown {
    position: absolute;
    left: 10px;
    bottom: 53px;
    min-width: 270px;
    background-color: #222222;
    border-radius: 12px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.35), 0px 3px 1px -1px rgba(255, 255, 255, 0.35) inset,
        0px 1px 0 0px #000 inset;
    display: flex;
    box-sizing: border-box;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    z-index: 99;
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    @media (max-width: 450px) {
        bottom: 56px;
    }
}

.account-dropdown-details {
    border-radius: 100%;
    background-color: #215caf;
    font-size: 18px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.dropdown-container {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    padding: 16px;
    width: 100%;
}
.dropdown-seperator {
    height: 1px;
    width: 99.8%;
}
.dropdown-seperator-black {
    height: 1px;
    width: 99.8%;
}

.dropdown-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    color: #ffffff;
}

.dropdown-name {
    color: #ffffff;
    font-family: 'DIN Next W1G Medium';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.dropdown-email {
    color: #ffffff;
    font-family: 'DIN Next W1G';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    opacity: 0.8;
}

// help ui
.help-icons {
    position: absolute;
    left: 6px;
    bottom: 0;
}

.logout-text {
    font-family: 'DIN Next W1G Medium';
    font-size: 16px;
    font-style: normal;
    color: #e2001a;
    font-weight: 700;
    line-height: 130%;
}

.logout-button {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 16px;
    gap: 8px;
    width: 100%;
    cursor: pointer;
}

.logout-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.help-text {
    font-family: 'DIN Next W1G';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    color: #ffffff;
}

.account-dropdown-item {
    width: 100%;
    min-width: 236px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    padding: 16px;
    cursor: pointer;
}

.account-dropdown-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.contact-item {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}

.dropdown-contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.help-dropdown {
    position: absolute;
    left: 10px;
    bottom: 40px;
    min-width: 270px;
    background-color: #222222;
    border-radius: 12px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.35), 0px 3px 1px -1px rgba(255, 255, 255, 0.35) inset,
        0px 1px 0 0px #000 inset;
    display: flex;
    box-sizing: border-box;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    z-index: 99;
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    @media (max-width: 450px) {
        bottom: 2px;
    }
}

.mob-collpased {
    transform: translateX(-100%);
    padding: 0;
    overflow: hidden;
}
