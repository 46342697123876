.icon-badge-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #000000;
    box-shadow: 0px 1px 0.5px 0px #FFFFFF59 inset, 0px 2px 4px 0px #00000059;
    background-color: #222222;

    &.small {
        width: 48px;
        height: 48px;

        box-shadow: 0px 1px 1px 0px #FFFFFF59 inset, 0px 2px 4px 0px #00000059;
    }

    &.large {
        width: 78px;
        height: 78px;
    }

    .shadow-icon {
        position: absolute;
        z-index: 1;
    }

    .badge-icon {
        z-index: 2;
    }

    .lock-shadow {
        top: 34px;
        left: 29px;
    }

    .favorite-icon, .shield-icon, .science-icon, .human-icon {
        position: absolute;
        top: 23px;
        left: 50%;
        transform: translateX(-50%);
    }

    .favorite-shadow {
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
    }

    .shield-shadow {
        top: 26.5px;
        left: 50%;
        transform: translateX(-50%);
    }

    .science-shadow {
        top: 26.5px;
        left: 25.5px;
    }    
}