@use '../../styles/spacing' as *;

.session-container {
    height: var(--session-container-height, calc(100% - 100px));
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-top: 98px !important;

    @media screen and (max-width: 1280px) {
        padding: 98px 0 0 0 !important;
    }
}

.session-container-mob {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-top: 60px !important;
    padding-right: 8px !important;
    width: 100%;
    height: var(--session-container-height, calc(100%));
}

.session-container::-webkit-scrollbar {
    display: none;
}

.session-input-container {
    position: absolute;
    height: auto;
    padding: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @media (max-width: 450px) {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 0;
    }

    @media (max-width: 1280px) {
        max-width: 872px !important;
    }

    @media (max-width: 1700px) {
        padding: 0 24px;
    }
}

.session-bar-container {
    position: relative;
    max-width: 872px;
    margin: 0 auto;
    display: flex;

    &.right-menu-active {
        @media (max-width: 1510px) {
            max-width: calc(100% - 700px) !important;
        }

        @media (max-width: 1400px) {
            max-width: calc(100% - 560px) !important;
        }

        @media (max-width: 1700px) {
            padding: 0 24px !important;
        }

        &.left-menu-active {
            @media (max-width: 1400px) {
                max-width: calc(100% - 480px) !important;
                margin-left: 200px;
            }
        }
    }

    @media (max-width: 1280px) {
        max-width: calc(100% - 350px);
    }

    @media (max-width: 450px) {
        display: none;
    }

    @media (min-width: 1510px) {
        &.left-menu-active {
            max-width: calc(100% - 700px) !important;
        }
    }
}

.session-bar-container-mob {
    width: 100%;
    display: none;
    @media (max-width: 450px) {
        position: static;
        padding-left: 9px;
        display: flex;
        justify-content: center;
        align-items: start;
        height: calc(100vh - 102px);
        gap: 8px;
    }
}

.set-session-container {
    border-radius: var(--border-border-radius-border-radius-12, 12px) var(--border-border-radius-border-radius-12, 12px) 0px
        0px;
    border: 1px solid #000;
    background: var(--surface-surface-primary, #222);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.35), 0px 2px 1px 0px rgba(255, 255, 255, 0.35) inset;
    padding: 7px;
    position: fixed;
    z-index: 999;
    bottom: 0;
    display: none;
    @media (max-width: 450px) {
        display: none;
    }
}
.set-session {
    border-radius: 8px;
    width: 100%;
    height: 142px;
    padding: 17px 13px 10px 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    background-color: #f7f5eef2;
    &__title {
        text-align: start;
        width: 100%;
        color: #000;
        /* 16-Bold-Titel */
        font-family: 'DIN Next W1G';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 20.8px */
    }
    &__desc {
        text-align: start;
        width: 100%;
        color: #000;

        /* 16-Regular */
        font-family: 'DIN Next W1G Light';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
    }
    &__footer {
        width: 100%;
        color: #000;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__footer-start {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
    }
    &__footer-cancel {
        border-radius: 2px;
        border: 1px solid var(--black-light-151, rgba(34, 34, 34, 0.2));
        padding: 8px 16px;
        /* 14 Medium Interaction */
        font-family: 'DIN Next W1G';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 14px */
    }
    &__footer-edit {
        border-radius: 2px;
        border: 1px solid var(--black-light-151, rgba(34, 34, 34, 0.2));
        padding: 8px 16px;
        /* 14 Medium Interaction */
        font-family: 'DIN Next W1G';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 14px */
    }
    &__footer-end {
        border-radius: 2px;
        border: 1px solid var(--black-light-151, rgba(34, 34, 34, 0.2));
        padding: 8px 16px;
        color: #fff;
        background-color: #000;

        /* 14 Medium Interaction */
        font-family: 'DIN Next W1G';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 14px */
    }
}

.hover-container {
    width: 169px;
    height: 181px;
    position: absolute;
    bottom: 0;
    z-index: 2;
}

.goal-session-container {
    width: 100%;
    height: 190px;
    position: fixed;
    z-index: 999;
    bottom: 0;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background: var(--surface-surface-primary, #222);
    box-shadow: 0px 2px 1px 0px rgba(255, 255, 255, 0.35) inset, 0px 1px 1px 0px rgba(20, 20, 20, 0.2);
    padding: 20px 30px;
    display: none;
    @media (max-width: 450px) {
        display: none;
    }
}

.goal-set {
    position: absolute;
    background: url(http://localhost:3000/static/media/session-goal-set.5a99c475f05384469c35.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 98%;
    height: 63%;
    top: -7px;
    left: -9px;
}

.goal-session {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-top: 66px;
    &__title {
        color: var(--text-text-color-text-inverted, #fff);

        /* 16-Bold-Titel */
        font-family: 'DIN Next W1G';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 20.8px */
    }
    &__desc {
        color: var(--text-text-color-text-inverted, #fff);

        /* 16-Regular */
        font-family: 'DIN Next W1G';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        opacity: 0.8;
    }
}

.test-hidden-message {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
}
