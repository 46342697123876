@font-face {
    font-family: "DIN Next W1G";
    src: url("./assets/fonts/dinnextw1g.otf") format('truetype');
}

@font-face {
    font-family: "DIN Next W1G Light";
    src: url("./assets/fonts/dinnextw1g_light.otf") format('truetype');
}

@font-face {
    font-family: 'DIN Next W1G Black';
    src: url("./assets/fonts/dinnextw1g_black.otf") format("truetype");
}

@font-face {
    font-family: 'DIN Next W1G Black Italic';
    src: url("./assets/fonts/dinnextw1g_blackitalic.otf") format("truetype");
}

@font-face {
    font-family: 'DIN Next W1G Bold';
    src: url("./assets/fonts/dinnextw1g_bold.otf") format("truetype");
}

@font-face {
    font-family: 'DIN Next W1G Bold Italic';
    src: url("./assets/fonts/dinnextw1g_bolditalic.otf") format("truetype");
}

@font-face {
    font-family: 'DIN Next W1G Heavy';
    src: url("./assets/fonts/dinnextw1g_heavy.otf") format("truetype");
}

@font-face {
    font-family: 'DIN Next W1G Heavy Italic';
    src: url("./assets/fonts/dinnextw1g_heavyitalic.otf") format("truetype");
}

@font-face {
    font-family: 'DIN Next W1G Italic';
    src: url("./assets/fonts/dinnextw1g_italic.otf") format("truetype");
}

@font-face {
    font-family: 'DIN Next W1G Light Italic';
    src: url("./assets/fonts/dinnextw1g_lightitalic.otf") format("truetype");
}

@font-face {
    font-family: 'DIN Next W1G Medium';
    src: url("./assets/fonts/dinnextw1g_medium.otf") format("truetype");
}

@font-face {
    font-family: 'DIN Next W1G Medium Italic';
    src: url("./assets/fonts/dinnextw1g_mediumitalic.otf") format("truetype");
}

@font-face {
    font-family: 'DIN Next W1G UltraLight';
    src: url("./assets/fonts/dinnextw1g_ultralight.otf") format("truetype");
}

@font-face {
    font-family: 'DIN Next W1G UltraLight Italic';
    src: url("./assets/fonts/dinnextw1g_ultralightitalic.otf") format("truetype");
}


body {
    font-family: 'DIN Next W1G' !important;
    background-color: #222222 !important;
    color: rgb(0, 0, 0);
    font-size: 15px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-synthesis: none;
}

#logo-container {
    position: absolute;
    top: 10px;
    left: 30px;
    display: flex;
    align-items: center;
    margin-top: 40px;
}

#logo {
    height: 100px;
    width: auto;
    margin-right: 10px;
    margin-bottom: 10px;
}

#title {
    font-size: 35px;
    margin: 0;
}

.in-development {
    color: red;
    font-size: 20px;
}

.App {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
}

#state_machine-container {
    width: 100%;
    height: 25%;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
}

#left-container, #links-container, #right-container {
    overflow-y: auto;
    padding: 10px;
}

#left-container, #right-container {
    width: 33.33%;
}

#links-container {
    width: 33.33%;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
}

#state_machine-container h2 {
    margin-top: 0;
}

#state_machine-container h3 {
    font-size: 18px;
    margin-bottom: 5px;
    color: #333;
}

#state_machine-container p {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 5px;
    line-height: 1.2;
    color: #666;
}

#chat-container {
    width: 100%;
    height: 75%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px; /* Add bottom margin to ensure it's not hidden behind the ControlRow */
    overflow: hidden;
}

#messages {
    flex-grow: 1;
    border: 1px solid #ccc;
    background: #e9e9e9;
    overflow-y: auto;
    padding: 8px;
    box-sizing: border-box;
}

#message-input {
    width: calc(100% - 120px);
    padding: 10px;
    box-sizing: border-box;
    margin-right: 10px;
    float: left;
}

.message {
    width: 99.5%;
    padding: 4px;
    background-color: #f1f1f1;
    border-radius: 8px;
    margin-bottom: 5px;
}

.user-message {
    background-color: #d1f1d1;
    padding: 5px;
    border-radius: 8px;
    margin-bottom: 5px;
}

.server-message {
    background-color: #adbaf5;
    padding: 5px;
    border-radius: 8px;
    margin-bottom: 5px;
}

.chat-button, .reset-button, .language-toggle {
    font-family: monospace;
    padding: 10px 20px;
    font-size: 1em;
    text-decoration: none;
    color: white;
    background-color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 5px;
    margin-bottom: 5px;
    transition: background-color 0.3s;
}

.chat-button:hover, .reset-button:hover, .language-toggle:hover {
    background-color: grey;
}

.button-row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background: #fff;
    border-top: 1px solid #ccc;
}

.control-row {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.modal-header,
.modal-body {
    padding: 10px;
}

.SpeechModal {
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    overflow-y: auto;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    position: relative;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.session {
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    margin: 5px;
    transition: background-color 0.3s ease;
}
.session:hover {
    background-color: #f1f1f1;
}
.active-session {
    font-weight: bold;
    background-color: #f1f1f1;
}

.cursor-pointer {
    cursor: pointer;
}

html{
    scroll-behavior: auto;
}

html, body {
    overscroll-behavior: none;
}