@use '../../styles/colors' as *;

.right-sidebar {
    position: absolute;
    top: 0;
    right: 0;
    width: 342px;
    height: 100%;
    padding: 24px;
    background-color: #dfdedb;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    border-left: 1px solid #b8b8b0;
    box-shadow: 3px 3px 3px 0px #00000008 inset;
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    overflow: hidden;
    box-sizing: border-box;

    @media (max-width: 1400px) {
        width: 280px;
    }

    @media (max-width: 450px) {
        transform: translateX(100%) !important;
    }

    &__sessions-box {
        height: 70%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow-y: auto;
        scrollbar-width: none;

        .cards-gradient {
            top: calc(70% - 85px);
            width: calc(100% - 48px);

            &.top {
                top: 24px;
            }
        }
    }

    &.open {
        opacity: 1;
        transform: translateX(0);
    }

    .file {
        border-radius: 12px;
        border: 1px solid #fff;
        background: rgba(245, 244, 243, 0.8);
        box-shadow: 0px 4px 2px 0px rgba(255, 255, 255, 0.5) inset, 0px 2px 4px 0px rgba(117, 117, 117, 0.3);
        backdrop-filter: blur(56px);
        padding: 10px;
        width: 100%;
        max-width: 100%;
        overflow-x: hidden;
        box-sizing: border-box;
        display: block;

        &__extension {
            border-radius: 6px;
            border: 1px solid #000;
            background: $eth-black-light;
            box-shadow: 0px 2px 1px 0px rgba(255, 255, 255, 0.35) inset;
            text-align: center;
            padding: 12px 0;
            min-width: 48px;
            text-transform: uppercase;
            margin-right: 12px;
        }

        &__name {
            .eth-text {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                box-sizing: border-box;
            }
        }

        &__last-updated {
            font-size: 12px;
            line-height: 150%;
        }

        &__wrapper {
            width: 100%;
            overflow: hidden;
        }
    }

    &__sources {
        position: relative;
        width: 100%;
        height: 30%;

        &--wrapper {
            border-top: 1px solid #ffffff;
            overflow-y: auto;
            overflow-x: hidden;
            box-sizing: border-box;
            scrollbar-width: none;
            height: 100%;
            padding-bottom: 50px;

            .links-wrapper {
                margin-bottom: 4px;

                .link {
                    display: inline-flex;
                    width: 100%;

                    div {
                        max-width: calc(100% - 24px);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-decoration: underline !important;
                        margin-bottom: 12px;

                        a {
                            color: $eth-black;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 150%;
                        }
                    }
                }
            }
        }

        &--title {
            border-bottom: 1px solid #bbbbb4;
        }
    }
}

.sources-gradient, .cards-gradient {
    height: 85px;
    background: linear-gradient(180deg, rgba(223, 222, 219, 0) 0%, rgba(223, 222, 219, 0.00838519) 11.79%, rgba(223, 222, 219, 0.0324148) 21.38%, rgba(223, 222, 219, 0.0704) 29.12%, rgba(223, 222, 219, 0.120652) 35.34%, rgba(223, 222, 219, 0.181481) 40.37%, rgba(223, 222, 219, 0.2512) 44.56%, rgba(223, 222, 219, 0.328119) 48.24%, rgba(223, 222, 219, 0.410548) 51.76%, rgba(223, 222, 219, 0.4968) 55.44%, rgba(223, 222, 219, 0.585185) 59.63%, rgba(223, 222, 219, 0.674015) 64.66%, rgba(223, 222, 219, 0.7616) 70.88%, rgba(223, 222, 219, 0.846252) 78.62%, rgba(223, 222, 219, 0.926281) 88.21%, #DFDEDB 100%);
    bottom: -24px;
    pointer-events: none;
    &.top {
        top: 58px;
        background: linear-gradient(0deg, rgba(223, 222, 219, 0) 0%, rgba(223, 222, 219, 0.00838519) 11.79%, rgba(223, 222, 219, 0.0324148) 21.38%, rgba(223, 222, 219, 0.0704) 29.12%, rgba(223, 222, 219, 0.120652) 35.34%, rgba(223, 222, 219, 0.181481) 40.37%, rgba(223, 222, 219, 0.2512) 44.56%, rgba(223, 222, 219, 0.328119) 48.24%, rgba(223, 222, 219, 0.410548) 51.76%, rgba(223, 222, 219, 0.4968) 55.44%, rgba(223, 222, 219, 0.585185) 59.63%, rgba(223, 222, 219, 0.674015) 64.66%, rgba(223, 222, 219, 0.7616) 70.88%, rgba(223, 222, 219, 0.846252) 78.62%, rgba(223, 222, 219, 0.926281) 88.21%, #DFDEDB 100%);
        z-index: 2;
    }
}

.document-wrapper {
    width: 30vw;
    height: calc(100vh - 40px);
    top: 8px;
    right: 8px;
    bottom: 8px;
    overflow-y: auto;
    border-radius: 12px;
    border: 1px solid #fff;
    background: rgba(245, 244, 243, 0.8);
    box-shadow: 0px 4px 2px 0px rgba(255, 255, 255, 0.5) inset, 0px 2px 4px 0px rgba(117, 117, 117, 0.3);
    backdrop-filter: blur(128px);
    scrollbar-width: none;
    padding: 25px 35px 0 35px;
    z-index: 3;

    @media (max-width: 450px) {
        width: 95%;
        height: 98vh;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10 !important;
        padding: 15px;
    }

    .document-title {
        margin-bottom: 29px;
    }

    canvas {
        width: 100% !important;
        height: auto !important;
    }

    .textLayer,
    .annotationLayer {
        display: none;
    }
}
